.public_list_page {
  background-color: #f5f5f5;
  padding: 0 20px 20px 20px;
  width: calc(100% - 250px);
  min-height: 100%;
  overflow-y: auto;

  .table_box {
    padding: 20px;
    background-color: #fff;
  }

  .func {
    display: flex;
    justify-content: flex-end;

    .func_item {
      margin-bottom: 20px;
    }
  }

  .page_num {
    text-align: right;
    margin-top: 20px;
  }
}

.public_table {
  position: relative;

  .ant-table-cell {
    .operation {
      span {
        color: #1890ff;
        cursor: pointer;
        margin: 0 5px;

        &:nth-child(1) {
          margin-left: 0;
        }

        &:hover {
          color: #49a7ff;
        }

        .print {
          color: red;
        }
      }
    }
  }
}

.public_detail_page {
  padding: 0 24px;
  background-color: #f0f2f5;
  width: calc(100% - 250px);
  min-height: 100%;

  .public_detail_header_title {
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
    margin-bottom: 24px;
  }

  .public_detail_header {
    padding: 12px 32px 12px;
    background-color: #fff;
  }

  .public_detail_content {
    margin: 16px 32px;

    .public_detail_tab_pane {
      padding: 32px 38px;
      background-color: #fff;
    }
  }
}

.ant-table-thead > tr > th {
  white-space: nowrap;
}

.page_statistics {
  background-color: #e8f4ff;
  border: 1px solid #80c2ff;
  border-radius: 2px;
  padding: 6px 14px;
  display: flex;
  justify-content: space-between;
  color: #515559;
}

.ant-table-container {
  overflow: scroll;
  max-height: 564px;
  position: relative;
  .ant-table-content {
    position: relative;
    overflow: visible !important;
    padding-top: 1px;
    .ant-table-thead {
      padding-top: 1px;
      z-index: 3;
      position: sticky;
      top: 0.1px;
    }
  }
}

