.page-not-find {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 40px;

  &-info {
    > p {
      margin: 15px 0;
      font-size: 24px;
    }
    &-url {
      a {
        display: block;
        width: 120px;
        height: 40px;
        line-height: 40px;
        color: black;
        font-size: 14px;

        &:link {
          color: #1890ff;
        }

        &:active {
          color: none;
        }

        &:visited {
          color: #000;
        }

        &:hover {
          color: #1890ff;
        }
      }
    }
  }

  img {
    width: 200px;
    height: 240px;
  }
}
