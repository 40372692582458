html,
body,
#root {
  margin: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* @font-face {
  font-family: 'iconfont'; 
  src: url('//at.alicdn.com/t/c/font_3981257_nw6pphg945.woff2?t=1679975643755') format('woff2'),
       url('//at.alicdn.com/t/c/font_3981257_nw6pphg945.woff?t=1679975643755') format('woff'),
       url('//at.alicdn.com/t/c/font_3981257_nw6pphg945.ttf?t=1679975643755') format('truetype');
} */
