.sign-container-input-by-message {
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 auto;
  svg {
    color: rgba(0, 122, 245, 1);
  }
  &-phone {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 380px;
    padding: 0;
    input {
      height: 36px;
      height: 36px;
      width: 100%;
    }
    margin: {
      bottom: 20px;
    }
    button {
      margin-left: 15px;
    }
  }
  &-sms {
    width: 380px;
    input {
      height: 36px;
      height: 36px;
      width: 100%;
    }
  }
  &-submit {
    width: 380px;
    margin: {
      top: 25px;
    }
    height: 40px;
    border-radius: 4px;
  }
}
.tenants {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 50%);

  .tenants_box {
    width: 600px;
    padding: 20px 30px 30px;
    background-color: #ffffff;
    border-radius: 4px;

    h3 {
      padding-bottom: 16px;
      font-family: "PingFang SC";
      font-size: 16px;
      font-weight: 500;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }

    .tenants_box_content {
      display: flex;
      flex-flow: wrap;
      max-height: 500px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        /* 滚动条整体样式 */
        width: 4px;

        /* 高宽分别对应横竖滚动条的尺寸 */
        height: 1px;
      }

      &::-webkit-scrollbar-thumb {
        background: #f4f7fc;

        /* 滚动条里面小方块 */
        border-radius: 4px;
        box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
      }

      &::-webkit-scrollbar-track {
        background: #f4f7fc;
        border-radius: 4px;

        /* 滚动条里面轨道 */
        box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
      }

      .tenants_box_item {
        width: 260px;
        padding: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        text-align: center;
        cursor: pointer;
        background-color: #f4f7fc;
        border: 1px solid #f4f7fc;
        border-radius: 2px;

        &:hover {
          font-weight: 500;
          color: #007af5;
          background-color: #e7f1fe;
          border: 1px solid #1890ff;
        }

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        &:nth-child(2n - 1) {
          margin-right: 10px;
        }
      }
    }
  }
}
